/* global $ */

$(function() {

  if ($('#mapa').length) {
    // var styles = [{"featureType":"landscape","stylers":[{"saturation":-100},{"lightness":65},{"visibility":"on"}]},{"featureType":"poi","stylers":[{"saturation":-100},{"lightness":51},{"visibility":"simplified"}]},{"featureType":"road.highway","stylers":[{"saturation":-100},{"visibility":"simplified"}]},{"featureType":"road.arterial","stylers":[{"saturation":-100},{"lightness":30},{"visibility":"on"}]},{"featureType":"road.local","stylers":[{"saturation":-100},{"lightness":40},{"visibility":"on"}]},{"featureType":"transit","stylers":[{"saturation":-100},{"visibility":"simplified"}]},{"featureType":"administrative.province","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"labels","stylers":[{"visibility":"on"},{"lightness":-25},{"saturation":-100}]},{"featureType":"water","elementType":"geometry","stylers":[{"hue":"#ffff00"},{"lightness":-25},{"saturation":-97}]}];

    var mapCenter = {
      lat: 51.743966,
      lng: 18.062406
    }

    var map = new google.maps.Map(document.getElementById('mapa'), {
      zoom: 15,
      center: mapCenter,
      mapTypeId: 'roadmap',
      scrollwheel: false,
      draggable: true,
      //zoomControl: false,
      //disableDoubleClickZoom: true
    });

    var marker = new google.maps.Marker({
      position: mapCenter,
      map: map,
      icon: 'img/marker.png'
    });
  }

  // NAV Trigger
  $('.js-nav-trigger').on('click touchstart', function foo() {
    // open
    if ($('.js-nav-trigger').hasClass('is-active')) {
      $('.js-nav-trigger').removeClass('is-active');
      $('.c-nav__wrap').removeClass('is-active');
    // closed
    } else {
      $('.js-nav-trigger').addClass('is-active');
      $('.c-nav__wrap').addClass('is-active');
    }

    return false;
  });

  // HOME slider
  $('.c-slider').slick({
    lazyLoad: 'ondemand',
    slidesToScroll: 1,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true,
    speed: 500,
    arrows: false,
    cssEase: 'linear',
    dots: false,
    pauseOnHover: false,
    pauseOnFocus: false,
    prevArrow: '<button type="button" class="slick-arrow slick-prev ion-chevron-left"></button>',
    nextArrow: '<button type="button" class="slick-arrow slick-next ion-chevron-right"></button>',
  });

  // HOME NEWS slider
  $('.c-slider').on('click', function(){
    $('.c-slider').slick('slickNext');
  });

  // HOME NEWS slider
  $('.c-home-news__slider').slick({
    slidesToScroll: 3,
    slidesToShow: 3,
    autoplay: true,
    autoplaySpeed: 5000,
    // fade: true,
    speed: 500,
    arrows: false,
    cssEase: 'linear',
    dots: true,
    pauseOnFocus: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 639,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });


// Popup Gallery
  $('.gallery').magnificPopup({
    delegate: 'a',
    type: 'image',
    gallery: {
      enabled:true,
      tCounter: '%curr% / %total%'
    }
  });

// Popup Gallery
  $('.js-popup').magnificPopup({
    type: 'image'
  });

  // Fixd header
  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    var width = $(window).width();

    if (width >= 1200) {
      if (scroll >= 148) {
        $(".c-nav__wrap").addClass("is-fixed");
      }
      if (scroll < 148) {
        $(".c-nav__wrap").removeClass("is-fixed");
      }
    }
  });

});

